import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Button } from '@mui/material';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';

const InviteDialog = ({ open, onClose }) => {
    const authInfo = useAuthInfo();
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteError, setInviteError] = useState('');
    const [inviteSuccess, setInviteSuccess] = useState('');

    const handleInviteChange = (event) => {
        setInviteEmail(event.target.value);
        setInviteError('');
        setInviteSuccess('');
    };

    const handleInviteSubmit = async () => {
        if (!inviteEmail || !/\S+@\S+\.\S+/.test(inviteEmail)) {
            setInviteError('Not a valid email');
            return;
        }

        const { firstName, lastName, email } = authInfo.user;

        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/invite`, {
                inviteEmail,
                userFirstName: firstName,
                userLastName: lastName,
                userEmail: email,
            });

            setInviteSuccess("We'll send an invite to this person soon.");
            setInviteEmail('');
        } catch (error) {
            setInviteError('Failed to send invite. Try again later.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Share Coachable</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Get a free month every time a friend activates a plan 🥳
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={inviteEmail}
                    onChange={handleInviteChange}
                    error={!!inviteError}
                    helperText={inviteError || inviteSuccess}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleInviteSubmit} color="primary">
                    Invite
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InviteDialog;
