import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import InviteDialog from './InviteDialog';

const InviteCTA = () => {
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    return (
        <>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    Get a free month every time a friend activates a plan 🥳 {' '}
                </Typography>
                <Button 
                    variant="text" 
                    size="small" 
                    onClick={() => setInviteDialogOpen(true)} 
                    sx={{ textTransform: 'none' }}
                >
                    Share Coachable
                </Button>
            </Box>

            <InviteDialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} />
        </>
    );
};

export default InviteCTA;
